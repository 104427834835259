import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import React from "react"
import Layout from "../components/layout"
import HeroHeadline from "../components/heroheadline"
import * as hero from '../styles/hero.module.css'
import * as styles from '../styles/technology.module.css'
import Sectionheader from "../components/sectionheader"

export default function Technology() {
    const data = useStaticQuery(graphql`
        query TechHero {
            file(relativePath: {eq: "heroes/AdobeStock_245563453.jpeg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return(
        <Layout>
            <div className={hero.smarthero}>
                <Img fluid={data.file.childImageSharp.fluid} className={hero.smartheroimage}/>
                <HeroHeadline h1="SMART Technology" sub="" />
            </div>
            <div className={styles.who}>
                <div className="container">
                    <div className="row align-items-start">
                        <Sectionheader name="About SMART Technology" />
                        <div className="col-11 col-sm-8">
                            <p>SMART Technology is all about the exploration, research, design, and evaluation of SMART Connected Mobile and Wearable Technologies to improve and enhance teaching, learning, and the workforce. This includes investigation of immersive learning using the innovation of Mixed Reality Technologies of virtual (VR) and holograms (HR) simulations.</p>
                        </div>
                        <div className="col-11 col-sm-3">
                            <div className={styles.whocontact}>
                                <ul>
                                    <li>
                                        <a href="mailto:smartcenter@tnstate.edu" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}